import { useEffect, useState } from "react";
import { IGetResultsResponse } from "../../../infra/src/types";

const API_URL = process.env.GATSBY_API_URL!;
const API_KEY = process.env.GATSBY_API_KEY!;

interface IUseResults {
    fetchResults: (url: string) => void;
    results?: IGetResultsResponse;
    initialFetching: boolean;
}

export const useResults = (): IUseResults => {
    const [isInitialFetching, setInitialFetching] = useState<boolean>(false);
    const [isFetching, setFetching] = useState<boolean>(false);
    const [donePolling, setDonePolling] = useState<boolean>(false);
    const [results, setResults] = useState<IGetResultsResponse | undefined>(undefined)
    const [requestId, setRequestId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (requestId) {
            const interval = setInterval(async () => {
                if (isFetching || donePolling) return;
                setFetching(true);
                const result = await fetch(`${API_URL}?requestId=${requestId}`, {
                    headers: {
                        'x-api-key': API_KEY,
                    }
                });
                const data = await result.json() as IGetResultsResponse;
                setResults(data);
                if (!data.regions.some(region => region.status === "pending")) {
                    setDonePolling(true);
                }
                setFetching(false);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [donePolling, isFetching, requestId, results]);

    const fetchResults = async (url: string) => {
        setInitialFetching(true);
        const foo = await fetch(API_URL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-api-key': API_KEY,
            },
            body: JSON.stringify({ url })
        });
        const data = await foo.json() as IGetResultsResponse;
        setDonePolling(false);
        setRequestId(data.requestId);
        setResults(data);
        setInitialFetching(false);
    }

    return {
        fetchResults,
        results,
        initialFetching: isInitialFetching
    };
}