import { Input, Flex, Button, FormControl, FormLabel } from "@chakra-ui/react";
import React, { FormEventHandler, useState } from "react";

interface InputFieldProps {
    onSubmit: (url: string) => void;
    isLoading: boolean;
}

const InputField = ({ onSubmit, isLoading }: InputFieldProps) => {
    const [text, setText] = useState<string>("");

    const handleSubmit: FormEventHandler = (event) => {
        event.preventDefault();

        let urlToSubmit = text;
        if (!urlToSubmit.startsWith('http')) {
            urlToSubmit = `https://${text}`;
            setText(urlToSubmit);
        }

        onSubmit(urlToSubmit);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Flex alignItems={['flex-end']} justifyItems={"stretch"} justifyContent={"stretch"} direction={['column', 'row']}>
                <Input
                    placeholder="https://cdnchecker.net/"
                    bg="white"
                    value={text}
                    size="lg"
                    marginRight={[0, 2]}
                    marginBottom={[2, 0]}
                    onChange={({ target: { value } }) => {
                        setText(value)
                    }}
                />
                <Button
                    isFullWidth={false}
                    isLoading={isLoading}
                    colorScheme="blue"
                    size="lg"
                    onClick={() => onSubmit(text)}
                >
                    Get results!
                </Button>
            </Flex>
        </form>

    )
}

export default InputField;