import * as React from "react"
import { Container, Heading, Text, Link, Box, ListItem, UnorderedList } from "@chakra-ui/react"
import { Helmet } from "react-helmet"
import App from "../components/App"

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>CloudFront Edge Location Checker</title>
        <meta name="description" content="Check your website is being served from edge locations and your CloudFront configuration is correct" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://cdnchecker.net/" />
      </Helmet>
      <Container maxW="container.lg" marginTop="8">
        <Heading as="h1" marginBottom="4">CloudFront Edge Location Checker</Heading>
        <Text fontSize="xl" marginBottom="12">
          Getting CloudFront configuration right can be difficult. Use this tool to check your website
          is being served from a nearby {" "}
          <Link
            href="https://aws.amazon.com/cloudfront/features/?whats-new-cloudfront.sort-by=item.additionalFields.postDateTime&whats-new-cloudfront.sort-order=desc"
            target="_blank"
            color="blue.500"
            rel="noreferrer">
            CloudFront Point of Presence
          </Link>
          {" "} instead of one region.
        </Text>
        <App />
        <section style={{ marginBottom: 100 }}>
          <Heading as="h2" size="lg" marginTop="8" marginBottom="4">Need help?</Heading>
          <Heading as="h4" size="m" marginTop="8">None of my requests are being served by CloudFront</Heading>
          <UnorderedList paddingLeft={4} marginTop={2}>
            <ListItem>
              Check your domain is correctly pointed at the provided <code>{`UNIQUEID.cloudfront.net`}</code> domain.
              <br />
              You can find the domain name in your <Link target="_blank" color="blue.500" rel="noreferrer" href="https://console.aws.amazon.com/cloudfront/">CloudFront Distributions list</Link> in the AWS Console, in the "Domain name" column
            </ListItem>
          </UnorderedList>
          <Heading as="h4" size="m" marginTop="8">My requests aren't being served by the nearest Edge location (or PoP)</Heading>
          <Text>
            There are three <Link href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/PriceClass.html" target="_blank" color="blue.500" rel="noreferrer">price classes</Link> available
            for CloudFront; the Edge locations your CloudFront distribution uses is based on which price class you choose. To leverage all Edge locations, you
            should use <code>PriceClass_All</code> in your configuration.
          </Text>
          <Heading as="h4" size="m" marginTop="8">Where can I find more information about CloudFront?</Heading>
          <Text >You can find lots of information on the Amazon Web Services website</Text>
          <UnorderedList paddingLeft={4} marginTop={2}>
            <ListItem><Link target="_blank" color="blue.500" rel="noreferrer" href="https://aws.amazon.com/cloudfront/">Amazon CloudFront product page</Link></ListItem>
            <ListItem><Link target="_blank" color="blue.500" rel="noreferrer" href="https://aws.amazon.com/cloudfront/pricing/">Amazon CloudFront pricing</Link></ListItem>
            <ListItem><Link target="_blank" color="blue.500" rel="noreferrer" href="https://aws.amazon.com/cloudfront/faqs/">Amazon CloudFront FAQ's</Link></ListItem>
            <ListItem><Link target="_blank" color="blue.500" rel="noreferrer" href="https://docs.aws.amazon.com/cloudfront/latest/APIReference/">Amazon CloudFront API Reference</Link></ListItem>
            <ListItem><Link target="_blank" color="blue.500" rel="noreferrer" href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/">Amazon CloudFront Developer Guide</Link></ListItem>
            <ListItem><Link target="_blank" color="blue.500" rel="noreferrer" href="https://console.aws.amazon.com/cloudfront/">Amazon CloudFront AWS Console</Link></ListItem>
          </UnorderedList>
        </section>
      </Container>
    </main>
  )
}

export default IndexPage
