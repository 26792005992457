import React, { Fragment } from "react";
import { Table, Thead, Tr, Th, Tbody, Td, Tag, Text, Tooltip } from "@chakra-ui/react";
import { IRegion, IPop } from "../../../infra/src/types";

interface IResultsProps {
    regions: IRegion[]
}

const Results = ({ regions }: IResultsProps) => {

    const getPopLocationString = (pop: IPop): JSX.Element | string => {
        if (!pop.location) {
            const airportCode = pop.code.substr(0, 3)
            return (
                <Fragment>
                    {pop.code} - <Tooltip label={`A more precise area for your Edge location couldn't be found. It's probably near "${airportCode}" airport`} placement="auto-start"><u style={{ cursor: 'help' }}>Unknown</u></Tooltip>
                </Fragment>
            )
        }

        return `${pop.code} - ${pop.location.city}, ${pop.location.country}`
    }

    const getResultColumn = (region: IRegion): JSX.Element | string => {
        if (region.status === "pending") return 'Loading...';
        if (region.status === "failed") {
            return (
                <Tooltip label="An error when your URL was fetched. Check the URL and try again" placement="auto-start">
                    <Text className="failed">Failed</Text>
                </Tooltip>
            )
        }
        if (!region.pop) {
            return (
                <Tooltip label="There was nothing to suggest you are using CloudFront when we requested your URL" placement="auto-start">
                    <Text style={{ cursor: 'help' }}>¯\_(ツ)_/¯</Text>
                </Tooltip>
            )
        }

        return getPopLocationString(region.pop);
    }

    return (
        <Table variant="simple" size="sm" className="results">
            <Thead>
                <Tr>
                    <Th>AWS Region</Th>
                    <Th>Edge Location</Th>
                </Tr>
            </Thead>
            <Tbody>
                {regions.map((region) => (
                    <Tr>
                        <Td lineHeight="unset">{region.regionFriendly} <Tag>{region.region}</Tag></Td>
                        <Td>{getResultColumn(region)}</Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    )
}

export default Results;