import { Box, Text } from "@chakra-ui/layout"
import React, { Fragment } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { useResults } from "../hooks/useResults"
import InputField from "./InputField"
import Results from "./Results"

const App = () => {
    const { fetchResults, results, initialFetching } = useResults();

    const handleSubmit = (url: string) => {
        fetchResults(url);
        trackCustomEvent({
            category: 'Results - CTA',
            action: 'click',
            label: 'get results'
        });
    }

    return (
        <Fragment>
            <Box bg="blue.900" padding={[2, 10]} borderRadius="md" marginBottom="8">
                <InputField onSubmit={handleSubmit} isLoading={initialFetching} />
            </Box>
            {
                results?.regions && (
                    <Results regions={results.regions} />
                )
            }
        </Fragment>
    )
}

export default App;